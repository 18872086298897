<template>
  <div class="available-voucher-card" :class="{ 'selected': isSelected }" :style="{'--background': voucher?.is_valid != null && voucher?.is_valid == false ? '#F2F2F2' : '#FFF'}">
    <template v-if="isLoading">
      <b-skeleton-img no-aspect height="76px" width="100px" class="rounded-sm"></b-skeleton-img>
      <div class="voucher-card-info">
        <b-skeleton class="voucher-card-info-title" width="200px"></b-skeleton>
        <b-skeleton class="voucher-card-info-desc" width="150px"></b-skeleton>
        <b-skeleton class="voucher-card-info-desc" width="150px"></b-skeleton>
      </div>
    </template>
    <template v-else>
      <div class="voucher-card-img-container" :style="{ '--background': voucher?.is_valid != null && voucher?.is_valid == false ? '#ababab' : getVoucherType(voucher.type).bgColor}">
        <b-img :src="require('@/assets/img/voucher_whizliz-01.svg')" alt="" width="35" v-if="voucher.type == 'DISCOUNT'" />
        <b-img :src="require('@/assets/img/voucher_bp-01.svg')" alt="" width="35" v-else-if="voucher.type == 'RESELLER'" />
        <b-img :src="require('@/assets/img/voucher_free_shipping.svg')" alt="" width="35" v-else />
        <span class="voucher-card-type"> {{ getVoucherType(voucher.type).name }} </span> 
      </div>
      <div class="voucher-card-info flex-grow-1">
        <span class="voucher-card-info-title line-clamp-1">
          <span>{{ voucher.name }}</span>
          <template v-if="voucher.discount > 0">
            <span v-if="voucher.type != 'FREE DELIVERY'">  disc.<span class="text-gold bold" :class="{'voucher-invalid': voucher?.is_valid != null && voucher?.is_valid == false }"> {{ voucher.discount_type == 1 ? $helpers.format.price(voucher.discount) : `${voucher.discount}%` }}</span></span>
            <span v-if="voucher.max_value > 0"> up to <span :class="{'text-gold bold': voucher.type == 'FREE DELIVERY', 'voucher-invalid': voucher?.is_valid != null && voucher?.is_valid == false }">{{ $helpers.format.price(voucher.max_value) }}</span></span>
          </template>
        </span>
        <span class="voucher-card-info-title line-clamp-1" v-if="voucher.cashback > 0">Extra Commission <span class="text-gold bold">{{ voucher.cashback_type == 1 ? $helpers.format.price(voucher.cashback) : `${voucher.cashback}%` }}</span></span>
        <span class="voucher-card-info-desc">Min. Purchase {{ $helpers.format.price(voucher.min_purchase) }}</span>
        <span class="voucher-card-info-desc">
          <template v-if="$helpers.format.remainingDateDuration(voucher.end_date_epoch || voucher.valid_to_epoch, 'days') <= 7">
            Ends in {{ $helpers.format.remainingDateDuration(voucher.end_date_epoch || voucher.valid_to_epoch, "days") }} day(s)
          </template>
          <template v-else>
            Valid until {{ $helpers.format.epochMsToLocalTime(voucher.end_date_epoch || voucher.valid_to_epoch, "DD-MM-YYYY") }}
          </template>
          <span class="voucher-count-info" v-if="getRemainingVoucherCount(voucher) <= 20"> &#183; {{ getRemainingVoucherCount(voucher) == 0 ? `Usage limit reached` : `${getRemainingVoucherCount(voucher)} voucher(s) left` }}</span>
        </span>
        <span class="voucher-card-info-desc" v-if="voucher?.start_time && voucher?.end_time">Valid time {{ `${voucher.start_time} - ${voucher.end_time}` }}</span>
      </div>
      <fawesome-pro variant="fas" icon="chevron-right" class="text-gold pr-3" v-if="showChevron" @click.native="$emit('toDetailVoucher', $event, voucher)" />
    </template>
  </div>
</template>
<script>
export default {
  name: "VoucherCard",
  props: ["voucher", "showChevron", "isLoading", "isSelected", "isValid"],
  methods: {
    getVoucherType(type) {
      if (type == "DISCOUNT") return { name: "Whiz Voucher", bgColor: "#e2ac13" };
      else if (type == "RESELLER") return { name: "Business Partner", bgColor: "#443c26" };
      else return { name: "Free Shipping", bgColor: "#bd632f" };
    },
    getRemainingVoucherCount(voucher) {
      return voucher.count - voucher.used;
    },
  }
}
</script>

<style scoped>

.available-voucher-card {
  align-items: center;
  display: flex;
  background: var(--background, #FFF);
  border-radius: .45rem;
  cursor: pointer;
  box-shadow: 4px 4px 10px 0px rgba(210, 210, 210, 0.75);
  -webkit-box-shadow: 4px 4px 10px 0px rgba(210, 210, 210, 0.75);
  -moz-box-shadow: 4px 4px 10px 0px rgba(210, 210, 210, 0.75);
}

.available-voucher-card.selected {
  border: 2px #d89700 solid;
}

.available-voucher-card.selected .voucher-card-img-container {
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}

.available-voucher-card:not(:last-child) {
  margin-bottom: .5rem;
}

.voucher-card-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  flex: 0 0 100px;
  background: var(--background, #d89700);
  border-radius: .45rem;
  padding: .75rem;
}


.voucher-card-type {
  font-size: 10px;
  color: #fff;
  margin-top: 2px;
}

.voucher-card-info {
  display: flex;
  flex-direction: column;
  flex-grow: 0 1 auto;
  justify-content: center;
  padding: .5rem;
}

.voucher-card-info-title {
  font-size: 12px;
  line-height: 14px;
}
.voucher-card-info-desc {
  font-size: 10px;
  color: #757575;
  line-height: 13px;
}

.voucher-count-info {
  color: #B71C1C;
}
.text-gold.voucher-invalid {
  color: #757575 !important;
}
</style>